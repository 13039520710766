import React, { useEffect, useState } from 'react'
import useScrollToHash from '../utilities/useScrollToHash';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import PageBanner from '../Sections/PageBanner';
import { BsCheckCircle } from 'react-icons/bs';

function Booking() {
  useScrollToHash();
  const proposalPackages = [
    {
      name: "Basic",
      price: 550,
      includedServices: [
        "Marry Me Block Letters", "Hundreds of Silk Rose Petals", "Red Carpet", "Candles in Various Sizes", "Rose Stems", "Complete Setup and Cleanup"
      ]
    },
    {
      name: "Deluxe",
      price: 750,
      includedServices: [
        "Red Heart Arch", "Will You Marry Me LED Sign", "Hundreds of Silk Rose Petals", "Red Carpet", "Candles in Various Sizes", "Rose Stems", "Complete Setup and Cleanup"
      ]
    },
    {
      name: "Luxury",
      price: 1200,
      includedServices: [
        "Red Heart Arch", "Will You Marry Me LED Sign", "Hundreds of Silk Rose Petals", "Red Carpet", "Candles in Various Sizes", "Rose Stems", "Complete Setup and Cleanup", "Bouquet of Roses", "Fog Machine", "Complete Setup and Cleanup"
      ]
    },
    {
      name: "Platinum",
      price: 1750,
      includedServices: ["Luxury Event Setup Package", "La Ciel Dinner Package"]
    },
    {
      name: "Diamond",
      price: 2000,
      includedServices: ["Luxury Event Setup Package", "Premium La Ciel Dinner Package"]
    },
  ];
  const serviceAddOns = [
    {
      name: "Balloon Arches",
      options: [
        { label: "Themed Baloon Arch", value: "balloon-arches-themed-$300", price: 300 },
      ],
    },
    {
      name: "Foil Balloons",
      options: [
        { label: "Standard Bouquet", value: "foil-balloons-standard-$250", price: 250 },
        { label: "Custom theme and larger setup", value: "foil-balloons-custom-$400", price: 400 },
      ],
    },
    {
      name: "Assorted Balloons",
      options: [
        { label: "standard colours", value: "assorted-balloons-standard-$150", price: 150 },
        { label: "Themed Designs", value: "assorted-balloons-themed-$200", price: 200 },
      ],
    },
    {
      name: "Backdrops",
      options: [
        { label: "Fabric Backdrop", value: "backdrops-fabric-$300", price: 300 },
        { label: "Fabric and Accessories", value: "backdrops-accessories-$500", price: 500 },
      ],
    },
    {
      name: "Rose Bouquet",
      options: [
        { label: "Standard rose bouquet", value: "rose-bouquet-standard-$60", price: 60 },
        { label: "Themed ribbon bouquet", value: "rose-bouquet-themed-$150", price: 150 },
      ],
    },
    {
      name: "Cake",
      options: [
        { label: "Small Themed Cake", value: "cake-small-$90", price: 90 },
        { label: "Large Themed Cake", value: "cake-large-$150", price: 150 },
      ],
    },
    {
      name: "Streamers",
      options: [
        { label: "2 colours", value: "streamers-2colours-$50", price: 50 },
        { label: "3+ colours and themed", value: "streamers-themed-$90", price: 90 },
      ],
    },
    {
      name: "Chauffeur Service",
      options: [
        { label: "2 hours standard", value: "chauffeur-standard-$350", price: 350 },
      ],
    },
    {
      name: "360 Photo Booth",
      options: [
        { label: "2 hour rental", value: "photo-booth-2hr-$400", price: 400 },
        { label: "4 hour rental", value: "photo-booth-4hr-$750", price: 750 },
      ],
    },
  ];
  const proposalAddOns = [
    {
      name: "Photographer",
      price: 250
    },
    {
      name: "Videographer",
      price: 250
    },
    {
      name: "Limo Service",
      price: 250
    },
    {
      name: "Sparklers",
      price: 200
    },
    {
      name: "Champagne (2 bottles)",
      price: 50
    },
    {
      name: "Rose Bouquet",
      price: 40
    },
    {
      name: "Studio Time",
      price: 0
    },
  ]

  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [eventDateOption1, setEventDateOption1] = useState("");
  const [eventDateOption2, setEventDateOption2] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  // Event Type
  const [selectedService, setSelectedService] = useState('');
  // Proposal Package
  const [selectedPackage, setSelectedPackage] = useState('');
  //Proposal Add Ons
  const [selectedProposalAddOns, setSelectedProposalAddOns] = useState([]);
  // Specia Event AddOns
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [message, SetMessage] = useState("");
  const [accepted, setAccepted] = useState(false);
  //Track if form is submitted incase user wants to submit new request.
  const [formsubmitted, setFormSubmitted] = useState(false)
  //Total price
  const [totalPrice, setTotalPrice] = useState(0);

  // Handle checkbox changes for special services Add on
  const handleRadioChange = (optionValue, serviceName) => {
    setSelectedOptions((prevSelected) => [
      ...prevSelected.filter((value) => !value.startsWith(serviceName.toLowerCase().replace(/ /g, "-"))),
      optionValue
    ]);
  };
  // makes sure user has accepted terms and conditions
  const handleAcceptChange = () => {
    setAccepted(!accepted);
  };
  const handleSelectedService = (event) => {
    const value = event.target.value;
    setSelectedService(value);
    setSelectedPackage("");
    setSelectedProposalAddOns([]);
    setSelectedOptions([]);
    setAccepted(false);
    // console.log(value);
  }
  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };
  const handleProposalAddOns = (event) => {
    const value = event.target.value;
    setSelectedProposalAddOns((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value) // Remove if already selected
        : [...prevSelected, value] // Add if not selected
    );
  };
  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (accepted) {
      // console.log("User Information:");
      // console.log(`Name: ${userName}`);
      // console.log(`Phone: ${userPhone}`);
      // console.log(`Email: ${userEmail}`);
      // console.log("\nEvent Details:");
      // console.log(`Preferred Date 1: ${eventDateOption1}`);
      // console.log(`Preferred Date 2: ${eventDateOption2}`);
      // console.log(`Start Time: ${eventStartTime}`);
      // console.log(`End Time: ${eventEndTime}`);
      // console.log("\nSelected Services:");
      // console.log(`Main Service: ${selectedService}`);
      // console.log(`Proposal Package: ${selectedPackage}`);
      // console.log("Proposal Add-Ons:");
      // selectedProposalAddOns.forEach((addOn) => console.log(`- ${addOn}`));
      // console.log("Special Event AddOns:");
      // selectedOptions.forEach((addOn) => console.log(`-${addOn}`))
      // console.log("\nMessage:");
      // console.log(message);
      // console.log(`Total Price: ${totalPrice}`);

      const formattedData = {
        userName: userName,
        userPhone: userPhone,
        userEmail: userEmail,
        eventDateOption1: eventDateOption1,
        eventDateOption2: eventDateOption2,
        eventStartTime: eventStartTime,
        eventEndTime: eventEndTime,
        selectedService: selectedService,
        selectedPackage: selectedPackage,
        selectedProposalAddOns: [selectedProposalAddOns.join(", ")],
        selectedOptions: [selectedOptions.join(", ")],
        message: message,
        totalPrice: totalPrice,
      };
      // console.log(formattedData);

      // formattedData = {
      //   ...formData,
      //   selectedProposalAddOns: formData.selectedProposalAddOns.join("\n- "),
      //   selectedOptions: formData.selectedOptions.join("\n- "),
      // };

      emailjs
        .send(
          "service_62zqn11", // Replace with your EmailJS Service ID
          "template_j73o7zj", // Replace with your EmailJS Template ID
          formattedData,
          "vt7TUxBV1vrnbY0lm" // Replace with your EmailJS Public Key
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            // alert("Your booking information has been sent!");
          },
          (err) => {
            console.error("FAILED...", err);
            // alert("Failed to send the booking information.");
          }
        );
      // Reset form after submission
      setFormSubmitted(true);
      setUserName("");
      setUserPhone("");
      setUserEmail("");
      setEventDateOption1("");
      setEventDateOption2("");
      setEventStartTime("");
      setEventEndTime("");
      setSelectedService("");
      setSelectedPackage("");
      setSelectedProposalAddOns([]);
      setSelectedOptions([]);
      SetMessage("");
      setAccepted(false)
    } else {
      alert("Please accept the Terms and Conditions to proceed.");
    }
  };

  useEffect(() => {
    let price = 0;

    // Add selected proposal package price
    const selectedPackageObj = proposalPackages.find(pkg => `${pkg.name} ${pkg.price}` === selectedPackage);
    if (selectedPackageObj) {
      price += selectedPackageObj.price;
    }

    // Add Selected Proposal Add on Price
    proposalAddOns.forEach(addOn => {
      if (selectedProposalAddOns.includes(addOn.name)) {
        price += addOn.price;
      }
    });

    // Add Sepected Special event price
    serviceAddOns.forEach((addOn) => {
      addOn.options.forEach((option) => {
        if (selectedOptions.includes(option.value)) {
          price += option.price;
        }
      });
    });

    // Update the total price state
    setTotalPrice(price);
  }, [selectedService, selectedPackage, selectedProposalAddOns, selectedOptions]);

  return (
    <div className="bg-[#e2e2e2] min-h-screen">
      <Helmet>
        <title>Book Your Event | Nostalgic Moments Calgary Event Planning</title>
        <meta
          name="description"
          content="Ready to make your special event unforgettable? Book your event planning with Nostalgic Moments and get a personalized quote for your celebration. Let us bring your vision to life."
        />
        <meta
          name="keywords"
          content="Calgary event booking, book event planning Calgary, custom event planning quotes, Calgary party planners, event planning services Calgary, proposal planning booking, birthday party booking Calgary, graduation planning quotes, luxury event planning Calgary, event consultation Calgary, special event booking, book event planner, Calgary event services, get a quote for event planning"
        />
      </Helmet>
      {/* Banner Section */}
      <PageBanner text="Book your Unforgettable Event"
        backgroundImage="https://t3.ftcdn.net/jpg/09/91/86/82/360_F_991868214_K9b9RKlm3BgQAeGePVkZz5IeBOtH82VS.jpg"
      />

      {!formsubmitted ?
        <form
          className="section flex flex-col gap-6 mx-auto py-10 text-lg bg-neutral-300 border border-[#E0E0E0]"
          onSubmit={handleSubmit}
        >
          {/* Disclamer Section */}
          <div className='section'>
            <p>
              Every special event and proposal is unique—just like you and your vision. The following form is designed to
              help us understand your ideas and preferences, whether you're planning a romantic marriage
              proposal or a one-of-a-kind special event.
            </p>
            <p>
              We're here to assist with as much or as little as you need. From creating a breathtaking theme, choosing the
              perfect location, and organizing a seamless setup, to letting you hand-pick every last detail—we'll help bring
              your vision to life.
            </p>
            <p className='font-bold pt-5'>
              Once you've submitted the form, we'll reach out within 48 hours to schedule a free consultation call or online meeting.
              This session allows us to learn more about your event, discuss ideas, and ensure we're the perfect fit to make your
              occasion unforgettable.
            </p>
          </div>

          {/* User Information */}
          <h2 className="text-3xl font-bold text-[#1F2937] mt-3 text-center">Your Information</h2>
          <div className="flex flex-wrap gap-6">
            <div className="flex-1 flex flex-col">
              <label htmlFor="name" className="text-sm font-medium text-[#2C2C54] mb-1">*Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={userName}
                onChange={(e) => { setUserName(e.target.value) }}
                className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:ring-[#2C2C54] focus:ring-2 focus:outline-none shadow-sm"
                placeholder="Enter your full name"
                required
              />
            </div>
            <div className="flex-1 flex flex-col">
              <label htmlFor="phone" className="text-sm font-medium text-[#2C2C54] mb-1">*Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={userPhone}
                onChange={(e) => { setUserPhone(e.target.value) }}
                className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:ring-[#2C2C54] focus:ring-2 focus:outline-none shadow-sm"
                placeholder="Enter your phone number"
                required
              />
            </div>
            <div className="flex-1 flex flex-col">
              <label htmlFor="email" className="text-sm font-medium text-[#2C2C54] mb-1">*Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={userEmail}
                onChange={(e) => { setUserEmail(e.target.value) }}
                className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:ring-[#2C2C54] focus:ring-2 focus:outline-none shadow-sm"
                placeholder="Enter your email address"
                required
              />
            </div>
          </div>

          {/* Event Information */}
          <h2 className="text-3xl font-bold text-[#1F2937] mt-3 text-center">Event Information</h2>
          <div className="flex flex-col gap-6">
            {/* Event Type */}
            <div className="flex flex-col gap-3">
              <label htmlFor="eventType" className="text-sm font-medium text-[#2C2C54]">
                *Event Type
              </label>
              <select
                id="eventType"
                name="eventType"
                className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:outline-none focus:ring-2 focus:ring-[#2C2C54] shadow-sm w-full"
                defaultValue=""
                onChange={handleSelectedService}
                required
              >
                <option value="" disabled className="text-gray-400">
                  Select Event Type
                </option>
                <option value="Proposal">Proposal</option>
                <option value="Birthday">Birthday</option>
                <option value="Graduation">Graduation</option>
                <option value="Anniversary">Anniversary</option>
                <option value="Baby Shower">Baby Shower</option>
                <option value="Gender Reveal">Gender Reveal</option>
                <option value="Themed Party">Themed Party</option>
                <option value="Pre-Wedding Event">Pre-Wedding Event</option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Event Dates */}
            <div className="flex flex-wrap gap-6">
              <div className="flex flex-col flex-1">
                <label
                  htmlFor="eventDate1"
                  className="text-sm font-medium text-[#2C2C54] mb-2"
                >
                  *Requested Date (First Choice)
                </label>
                <input
                  type="date"
                  id="eventDate1"
                  name="eventDate1"
                  value={eventDateOption1}
                  onChange={(e) => setEventDateOption1(e.target.value)}
                  className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:outline-none focus:ring-2 focus:ring-[#2C2C54] shadow-sm"
                  required
                />
              </div>
              <div className="flex flex-col flex-1">
                <label
                  htmlFor="eventDate2"
                  className="text-sm font-medium text-[#2C2C54] mb-2"
                >
                  *Requested Date (Second Choice)
                </label>
                <input
                  type="date"
                  id="eventDate2"
                  name="eventDate2"
                  value={eventDateOption2}
                  onChange={(e) => setEventDateOption2(e.target.value)}
                  className="border border-[#E0E0E0] rounded-lg p-3 text-sm focus:outline-none focus:ring-2 focus:ring-[#2C2C54] shadow-sm"
                  required
                />
              </div>
            </div>

            {/* Event Times */}
            <div className="flex gap-6">
              <div className="flex flex-col flex-1">
                <label htmlFor="startTime">*Event Start Time</label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  value={eventStartTime}
                  onChange={(e) => { setEventStartTime(e.target.value) }}
                  className="border rounded-md p-2"
                  required
                />
              </div>
              <div className="flex flex-col flex-1">
                <label htmlFor="endTime">*Event End Time</label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  value={eventEndTime}
                  onChange={(e) => { setEventEndTime(e.target.value) }}
                  className="border rounded-md p-2"
                  required
                />
              </div>

            </div>
            <p className='text-center pb-5 text-base'>*Please note you must book at least 3 week prior to your event date unless otherwise previously communicated</p>
            {/* Proposal Packages */}
            {selectedService === "Proposal" ?
              <>
                <h2 className="text-3xl font-bold text-[#1F2937] mb-6 text-center">
                  Select a Proposal Package That Interests You
                </h2>
                <div className="flex flex-wrap gap-6 justify-center mx-auto">
                  {proposalPackages.map((proposalPackage, index) => {
                    const isSelected = selectedPackage === `${proposalPackage.name} ${proposalPackage.price}`;
                    return (
                      <label
                        key={index}
                        className={`p-6 rounded-lg border shadow-lg cursor-pointer transition-transform transform w-80 ${isSelected
                          ? "bg-indigo-100 border-indigo-500 shadow-xl scale-105"
                          : "bg-white border-gray-300 hover:shadow-xl hover:border-indigo-500 hover:scale-105"
                          }`}
                      >
                        <input
                          type="radio"
                          name="proposalPackage"
                          value={`${proposalPackage.name} ${proposalPackage.price}`}
                          checked={isSelected}
                          onChange={handlePackageChange}
                          className="hidden"
                        />
                        <div>
                          <h3
                            className={`text-xl font-semibold ${isSelected ? "text-indigo-700" : "text-[#1F2937]"
                              }`}
                          >
                            {proposalPackage.name}
                          </h3>
                          <p
                            className={`text-lg font-medium mt-2 ${isSelected ? "text-indigo-700" : "text-indigo-600"
                              }`}
                          >
                            ${proposalPackage.price}
                          </p>
                          <ul className="pt-4 border-t border-gray-800 text-left list-disc pl-5 space-y-2">
                            {proposalPackage.includedServices.map((service, i) => (
                              <li
                                key={i}
                                className={`text-gray-600`}
                              >
                                {service}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </label>
                    );
                  })}
                </div>

                <h2 className="text-3xl font-bold text-[#1F2937] pt-8 mb-6 text-center">
                  Select Additional Add-ons for Your Proposal
                </h2>
                <div className="flex flex-wrap gap-6 justify-center mx-auto">
                  {proposalAddOns.map((proposalAddOn, index) => {
                    const isSelected = selectedProposalAddOns.includes(proposalAddOn.name);
                    return (
                      <label
                        key={index}
                        className={`p-6 rounded-lg border shadow-lg cursor-pointer transition-transform transform w-80 ${isSelected
                          ? "bg-indigo-100 border-indigo-500 shadow-xl scale-105"
                          : "bg-white border-gray-300 hover:shadow-xl hover:border-indigo-500 hover:scale-105"
                          }`}
                      >
                        <input
                          type="checkbox"
                          name="ProposalAddOns"
                          value={proposalAddOn.name}
                          onChange={handleProposalAddOns}
                          checked={isSelected}
                          className="hidden"
                        />
                        <div>
                          <h3
                            className={`text-xl font-semibold ${isSelected ? "text-indigo-700" : "text-[#1F2937]"
                              }`}
                          >
                            {proposalAddOn.name}
                          </h3>
                          {proposalAddOn.price ? (
                            <p
                              className={`${isSelected ? "text-indigo-700 font-medium" : "text-indigo-600"
                                }`}
                            >
                              ${proposalAddOn.price}
                            </p>
                          ) : (
                            <p
                              className={`${isSelected ? "text-indigo-700 font-medium" : "text-gray-600"
                                }`}
                            >
                              Contact for Details
                            </p>
                          )}
                        </div>
                      </label>
                    );
                  })}
                </div>
              </>
              :
              <>
                {/* Special Event Add Ons */}
                <h2 className="text-3xl font-bold text-[#1F2937] mb-6 text-center">
                  Select Elements You Would Like Us to Provide for Your Event
                </h2>
                <div className="flex flex-wrap gap-6 justify-center mx-auto">
                  {serviceAddOns.map((addOn, index) => {
                    return (
                      <div key={index} className="mb-6 w-64 p-4 rounded-lg bg-white shadow-lg border border-gray-300">
                        <h3 className="text-xl font-semibold text-[#1F2937] mb-4">{addOn.name}</h3>
                        {addOn.options.map((option, idx) => {
                          const isSelected = selectedOptions.includes(option.value);
                          return (
                            <label
                              key={idx}
                              className={`flex items-center p-2 transition-all border-t border-gray-300 hover:bg-indigo-100 cursor-pointer rounded-lg ${isSelected ? "bg-indigo-50 border-indigo-500" : ""}`}
                            >
                              <input
                                type="radio"
                                name={addOn.name}
                                value={option.value}
                                checked={isSelected}
                                onChange={() => handleRadioChange(option.value, addOn.name)}
                                className="mr-3 hidden"
                              />
                              <span
                                className={`${isSelected ? "text-indigo-700 font-medium" : "text-[#1F2937]"}`}
                              >
                                {option.label}
                              </span>
                              {isSelected && (
                                <BsCheckCircle
                                  className="text-indigo-700 ml-auto"
                                />
                              )}
                            </label>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </>
            }
          </div>

          {/* Additioanl message */}
          <>
            <h2 className="text-3xl font-bold text-[#1F2937] mb-4 text-center">
              Additional Message:
            </h2>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => SetMessage(e.target.value)}
              className="w-full p-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all"
              placeholder="Type your message here..."
            />
          </>

          {/* Terms and Conditions */}
          <h2 className='text-2xl font-semibold text-[#2C2C54]'>Terms and Conditions</h2>
          <div>
            <p className='pb-10'>
              After your phone or video consultation and approval of the details for your unique special event or proposal package, we require a deposit of 50% of the total package price to secure your date and time. This deposit can be paid via e-Transfer to [Nostalgic.calgary@gmail.com]. The remaining balance is due 48 hours prior to your scheduled event date, payable using the same method.
              Please note that the 50% deposit is non-refundable in the event of cancellation. However, should you need to reschedule or adjust any details, an alternative date must be selected and approved at least 72 hours before the original scheduled event or proposal. The deposit will be transferred to the new date (rescheduled up to 6 months after the original booking).
              Rescheduling reasons can include personal matters, medical concerns, family emergencies, or weather conditions, but all changes must be communicated no less than 72 hours before your event.
              <br />
              If any interruptions occur during your proposal or special event (including weather, personal matters, or external factors), all payments remain non-refundable. If you choose to leave the setup before your scheduled end time, kindly notify a member of our team—either virtually or in person—at least 15 minutes before departure.
              Please note that refunds will not be provided for unused extra time, food, or add-on services such as games, photography, or décor enhancements.
            </p>
            <input
              type="checkbox"
              id="acceptTerms"
              name="terms"
              value="accepted"
              checked={accepted}
              onChange={handleAcceptChange}
              required
              className="w-5 h-5"
            />
            <label htmlFor="acceptTerms" className="text-gray-700 pl-3">
              I accept the Terms and Conditions
            </label>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-fit mx-auto bg-[#474787] hover:bg-[#2C2C54] text-white lg:text-lg font-medium py-4 px-28 transition-all backdrop-blur-md shadow-lg hover:scale-105 rounded-md mt-8"
          >
            Submit Booking Request
          </button>
        </form>
        :
        <div className="py-16 text-center section bg-[#f9f9f9] rounded-lg shadow-lg mx-auto max-w-3xl mt-20">
          <h2 className="text-4xl font-playfair text-[#2C2C54]">
            Form Successfully Submitted
          </h2>
          <p className="text-xl mt-4 font-playfair text-gray-600">
            Thank you for reaching out! One of our team members will contact you soon to finalize your unforgettable event.
          </p>
          <button
            onClick={() => setFormSubmitted(false)}
            className="w-fit mx-auto mt-8 bg-[#474787] hover:bg-[#2C2C54] text-white text-lg font-medium py-3 px-10 transition-all ease-in-out shadow-md hover:shadow-lg hover:scale-105 rounded-md"
          >
            Submit Another Booking Request
          </button>
        </div>
      }
      {/* Total Price Display */}
      {/* {(totalPrice !== 0 && formsubmitted !== true) &&
        <div className="z-50 fixed bottom-0 w-full bg-white border-t py-4 shadow-md text-center">
          <h2 className="text-3xl font-bold text-[#1F2937]">
            Total Estimated Price:
          </h2>
          <p className="text-indigo-500 text-2xl font-bold">${totalPrice}</p>
        </div>
      } */}
    </div>
  );
}

export default Booking