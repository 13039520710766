import React from 'react'
import NavigationButton from '../Components/Navigation/NavigationButton'

function CallToBookingSection() {
    return (
        <div className='py-16 text-center bg-[#191930]'>
            <div className="section flex flex-col ">
                {/* Header */}
                <div className="pb-10">
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Make It Memorable
                    </h2>
                    <h2 className="text-5xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Your Dream Event Awaits
                    </h2>
                </div>

                {/* Description */}
                <p className="text-lg sm:text-xl lg:text-2xl max-w-4xl mx-auto leading-relaxed text-[#bbbbbb]">
                    Every unforgettable celebration starts with a single step. Let us help you
                    transform your vision into reality. From heartfelt proposals to breathtaking
                    events, our team is ready to make your moments truly special.
                </p>

                {/* Booking Button */}
                <div className="flex justify-center mt-8">
                    <NavigationButton
                        link={"/booking/"}
                        title={"Book Now"}
                        className={"text-3xl md:px-36 py-2 bg-[#BBA085] rounded-lg shadow-lg transition-colors duration-300"}
                    />
                </div>
            </div>
        </div>
    )
}

export default CallToBookingSection
